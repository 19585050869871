import styled from "styled-components";

export const MainDiv = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 6px -6px #00000059;
  height: 80px;
  position: fixed;
  top: 220px;
  background: white;
  max-width: 1920px;
  width: 100%;
  z-index: 2;

  @media (max-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 425px) {
  justify-content: flex-start;
  }
`;

export const NavContainer = styled.div`
  display: flex;
`;

export const NavItem = styled.a`
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font: normal normal normal 22px/27px Montserrat;
  letter-spacing: 0px;
  color: var(--font-color);
  &.active {
    color: #007dc3;
    font-weight: bold;
  }

  @media (max-width: 1440px) {
    font: normal normal normal 18px Montserrat;
    padding-right: 0px;
  }
  @media (max-width: 425px) {
    padding-left: 10px;
    font: normal normal normal 14px/10px Montserrat;
  }
`;

export const NavHeader = styled.h5`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  font: normal normal bold 22px/27px Montserrat;
  letter-spacing: 0px;
  color: var(--font-color);
  text-transform: uppercase;

  @media (max-width: 1440px) {
    font: normal normal bold 16px Montserrat;
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 5px;
    padding-top: 5px;
  }
  @media (max-width: 425px) {
    font: normal normal bold 14px/20px Montserrat;
  }
`;
